import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useApi } from '../useApi';
import { useUserLanguage } from '../user/useUserLanguage';

interface ILocalization {
    name: string;
    value: string;
}

export interface IMappedLocales {
    [key: string]: string;
}

export const useLocalization = (filter: string[], disable = false) => {
    const { postGql } = useApi();

    const language = useUserLanguage() ?? 'en-Gb';

    return useQuery(
        ['locales', language, filter],
        async () => {
            const {
                localization: { localizations },
            } = await postGql<{ localization: { localizations: ILocalization[] } }>(
                gql`
                    query ($input: LanguageInput!) {
                        localization(input: $input) {
                            localizations {
                                name
                                value
                            }
                        }
                    }
                `,
                { input: { language, filter } },
                'locales'
            );

            const mapped: IMappedLocales = {};

            localizations.forEach((locale) => {
                mapped[locale.name] = locale.value;
            });

            return mapped;
        },

        { staleTime: 3600000, enabled: !!language && !disable }
    );
};
