export const translationsMock = {
    LL_DIGIVK_Alias_Label: 'Card alias',
    LL_DIGIVK_Alias_Notice_Length: 'Maximum 40 characters',
    LL_DIGIVK_Alias_Toast_Locked:
        'The Card ID cannot be changed as it has already been published. If you still wish to change it, please contact our service team.',
    LL_DIGIVK_Alias_Notice: "The change affects the map's web address.",
    LL_DIGIVK_Ebook_BuyNowCTA: 'Order e-book',
    LL_DIGIVK_Konfig_3_Mustertext_Input_Placeholder: 'You can edit your short presentation here.',
    LL_DIGIVK_MyLinks_Delete_Confirm: 'Are you sure you want to delete this link?',
    LL_DIGIVK_MyLinks_Label_Placeholder: 'Text displayed',
    LL_DIGIVK_MyLinks_Url_Placeholder: 'URL',
    LL_DIGIVK_MyLinks_Url_Validation: 'Please enter a valid URL with [https://...].',
    LL_DIGIVK_MyLinks_Statistics_Text: 'This link has been clicked [count] times.',
    LL_DIGIVK_MyLinks_MaxLinks_Text: 'Maximum number of links ([count] / [limit]) reached',
    LL_DIGIVK_MyLinks_Add: 'Insert new link',
    LL_DIGIVK_Status_Booked_Until: 'Booking is ongoing until',
    LL_DIGIVK_Status_Booked_Type: 'Booking type',
    LL_DIGIVK_Booking_365Days: '365 day extension',
    LL_DIGIVK_Booking_BookNow: 'Book now',
    LL_DIGIVK_Statistics_Headline: 'Statistics',
    LL_DIGIVK_Statistics_Copy_1:
        'Here you can see the total number of visits to your digital business card. The number of link impressions is shown under the respective link.',
    LL_DIGIVK_Statistics_Calls_Total: 'Total views',
    LL_DIGIVK_Statistics_Calls_For: 'Calls for',
    LL_DIGIVK_Download_AsImage: 'Download image file',
    LL_DIGIVK_Preview_Headline_Demo: 'Preview with demo data',
    LL_DIGIVK_Card_Saved_Successful: 'Card saved successfully',
    LL_DIGIVK_Konfig_QRDisplay: 'QR code for e-mails',
    LL_DIGIVK_Card_Privacy_Text: 'I hereby accept the',
    LL_DIGIVK_Card_Privacy_ButtonLabel: 'privacy policy',
    LL_General_DigitaleVisitenkarte: 'Digital business card',
    LL_DIGIVK_Headline_1: 'Your digital business card',
    LL_DIGIVK_Subline_1: 'QR code to your microwebsite',
    LL_DIGIVK_Copy_1:
        'Configure and save the settings for your microwebsite below. Your existing contact details and portrait picture will be automatically applied. After you have completed your booking, your personal QR code and your microwebsite will be generated. You will then see a mobile page preview on the right instead of the demo preview. Try out now with a free trial month by using the code TESTDCX in the shopping cart!',
    LL_DIGIVK_Subline_2: 'How to create a WhatsApp link',
    LL_DIGIVK_Copy_2:
        'Go to Web & Social Media Links below and select the WhatsApp logo. In the second line (URL), insert the following link with your international mobile number.1 Example phone no.: +44 07712 / 345 678 >> Example link: https://wa.me/4407712345678',
    LL_DIGIVK_Headline_2: 'Configuration',
    LL_DIGIVK_Konfig_1: 'Publish digital business card',
    LL_DIGIVK_Konfig_1_Copy_1: 'Set my microwebsite online / offline',
    LL_DIGIVK_Konfig_2: 'Publish my eBooks',
    LL_DIGIVK_Ebook_Copy_1:
        'You can display e-books of your property brochure on your digital business card. Under Menu/E-Books you manage the release of e-books for the digital business card.',
    LL_DIGIVK_Konfig_2_Copy_1: 'Activate e-books of your own account',
    LL_DIGIVK_Konfig_2_Copy_2: 'Activate e-books for the entire office',
    LL_DIGIVK_Konfig_3: 'Brief agent introduction',
    LL_DIGIVK_Konfig_3_Copy_1: 'Publish text',
    LL_DIGIVK_Konfig_3_Mustertext_Input_Headline: 'About me',
    LL_DIGIVK_Konfig_4: 'Web & social media links',
    LL_DIGIVK_Konfig_5: 'Show team',
    LL_DIGIVK_Konfig_4_Copy_1: 'Share contact details with my team',
    LL_DIGIVK_Konfig_4_Copy_2: 'Publish team',
    LL_DIGIVK_Status: 'Status',
    LL_DIGIVK_Status_02: '<i>-- Service inactive / page offline --</i>',
    LL_DIGIVK_Konfig_6: 'QR code to digital business card',
    LL_DIGIVK_Konfig_6_Copy_2b: 'QR code for testing - scan with your mobile phone',
    LL_DIGIVK_Speichern: 'Accept and save data',
    LL_DIGIVK_Status_Zustand_01: 'Individual booking',
    LL_DIGIVK_Status_Zustand_02: 'Office booking',
    LL_DIGIVK_Status_Zustand_03: 'Multi-office booking',
    LL_Themen_Sidebar_MyREMAXOffice: 'My RE/MAX Office',
    LL_FAQ: 'FAQ',
    LL_Tutorials: 'Tutorial videos',
    LL_Kontaktformular_PopUpHeader_Kontaktformular: 'Contact form',
    LL_Kontaktformular_Kontaktdaten: 'Contact details',
    LL_Kontaktformular_Herkunftsland: 'Country origin',
    LL_Kontaktformular_Sprache: 'Preferred language',
    LL_Kontakt_Anliegen_Headline: 'Request',
    LL_Kontakt_Anliegen_Copy:
        'Please tell us your request precisely. We will reply as soon as possible.',
    LL_Kontakt_Anliegen_Klassifikation: 'Choose topic',
    LL_Kontakt_Anliegen_Hilfe_Info: 'Assistance to solve your request',
    LL_Kontakt_Anliegen_Loesung_Headline: 'Possible solution:',
    LL_Kontakt_Anliegen_Tipp_Headline: 'Hint:',
    LL_Kontakt_Anliegen_Dropdown_Grund1: 'General shop questions',
    LL_Kontakt_Anliegen_Grund1_Problem1: 'I can‘t find the button to Buy.',
    LL_Kontakt_Anliegen_Grund1_Loesung: 'The Buy button is located up right in every product page.',
    LL_Kontakt_Anliegen_Grund1_Tipp:
        'Also scroll to the top of the shopping cart and in the ordering process to click to the next step.',
    LL_Kontakt_Anliegen_Dropdown_Grund2: 'Access / Login',
    LL_Kontakt_Anliegen_Grund2_Problem1: 'I forgot my login data.',
    LL_Kontakt_Anliegen_Grund2_Problem2: 'I cannot log in.',
    LL_Kontakt_Anliegen_Grund2_Problem3: 'I have no account yet.',
    LL_Kontakt_Anliegen_Grund2_Loesung:
        'With the login assistant on marketingcenter.remax.eu you can check your login data by entering your RE/MAX e-mail address and reset your data. If your email address is not found, make sure you have received an invitation email to register at the Marketing Center (check your spam folder). If this email is missing, please contact your regional office so that your account can be created.',
    LL_Kontakt_Anliegen_Grund2_Tipp:
        'If you have changed your office or your region, you will automatically get a new account.',
    LL_Kontakt_Anliegen_Dropdown_Grund3: 'Current order',
    LL_Kontakt_Anliegen_Grund3_Problem1: 'I have not received an order confirmation.',
    LL_Kontakt_Anliegen_Grund3_Loesung:
        'For payments by credit card or PayPal, click on <i>Make binding purchase</i> after the dialog window with the credit institution is no longer visible.',
    LL_Kontakt_Anliegen_Grund3_Tipp:
        'Order confirmations are sent immediately to your personal e-mail address. Check also your spam folder.',
    LL_Kontakt_Anliegen_Dropdown_Grund4: 'Delivery status',
    LL_Kontakt_Anliegen_Grund4_Problem1: 'I have a question about the shipping status of my order.',
    LL_Kontakt_Anliegen_Grund4_Problem2: 'I have only received part of my order.',
    LL_Kontakt_Anliegen_Grund4_Loesung:
        'Please check your shipping status in the menu at <i>Order history</i> and <i>Details</i>: There appears a blue truck icon with the info <i>Track delivery</i>. Remember that printed products and office/promotional items are shipped separately and will not be delivered together, even if you have ordered them at the same time.',
    LL_Kontakt_Anliegen_Grund4_Tipp:
        'If you cannot find the link to <i>Track delivery</i> in your order, then your delivery has not yet been sent.',
    LL_Kontakt_Anliegen_Dropdown_Grund5: 'Product availability',
    LL_Kontakt_Anliegen_Grund5_Problem1: 'I would like to order a very large quantity.',
    LL_Kontakt_Anliegen_Grund5_Loesung:
        'You can find our denominations and prices in the list below the information on the specifications of the products. If you would like to order a different or larger quantity, please contact us.',
    LL_Kontakt_Anliegen_Dropdown_Grund6: 'Invoices',
    LL_Kontakt_Anliegen_Grund6_Problem1: 'I can,t find an invoice and need it again.',
    LL_Kontakt_Anliegen_Grund6_Loesung:
        'Go to the <i>Order history</i> and <i>Details</i> menu. In the window that opens you will find your bills at the bottom. If you have a VAT ID, enter it in your profile and verify before you order. Subsequent changes to the invoice are not possible.',
    LL_Kontakt_Anliegen_Grund6_Tipp:
        'We only send invoices by e-mail. Also check your spam folder! To speed up the search, look for the order number. The sender is always: <i>order@marketingcenter.remax.eu</i>',
    LL_Kontakt_Anliegen_Dropdown_Grund7: 'Invoice reminder',
    LL_Kontakt_Anliegen_Grund7_Problem1: 'I want to see an invoice for a reminder.',
    LL_Kontakt_Anliegen_Grund7_Problem2:
        'I received a reminder even though the bill has been paid.',
    LL_Kontakt_Anliegen_Grund7_Loesung:
        'We first send a casual reminder. If there is still no payment after the set deadline, we will send two more reminders before the case is collected. Download the invoice for the reminder from the reminder-e-mail (blue invoice number).',
    LL_Kontakt_Anliegen_Grund7_Tipp:
        'You can also find invoices using the order number (G-RMC-xxx) in the menu under <i>Order history</i> and <i>Details</i>. Please send us a proof of payment to: <i>service@marketingcenter.remax.eu</i>. As soon as we have checked the case, we will contact you.',
    LL_Kontakt_Anliegen_Dropdown_Grund8: 'Portrait picture',
    LL_Kontakt_Anliegen_Grund8_Problem1: 'I see an old portrait picture.',
    LL_Kontakt_Anliegen_Grund8_Problem2: 'My profile picture does not appear in my profile.',
    LL_Kontakt_Anliegen_Grund8_Problem3: 'My profile picture was rejected.',
    LL_Kontakt_Anliegen_Grund8_Loesung:
        'It takes up to 48 hours to process your portrait photo. We will inform you by e-mail as soon as it is finished.',
    LL_Kontakt_Anliegen_Grund8_Tipp:
        'If your portrait photo does not meet the quality requirements, you will receive an e-mail so that you can upload another photo.',
    LL_Kontakt_Anliegen_Dropdown_Grund9: 'Trainings, webinars, tutorials',
    LL_Kontakt_Anliegen_Grund9_Problem1:
        'I need support to work more efficiently with the Marketing Center.',
    LL_Kontakt_Anliegen_Grund9_Loesung:
        'In the menu under Tutorial Videos you will find explanatory videos on the most important topics. Please subscribe to our newsletter to be informed about live webinars. If you need help at short notice, we can make an appointment for a personal online training. Just send us a date suggestion. Of course, our support is free of charge.',
    LL_Kontakt_Anliegen_Dropdown_Grund10: 'Wishes, suggestions for improvement',
    LL_Kontakt_Anliegen_Dropdown_Grund11: 'Other',
    LL_Kontaktformular_IhreNachricht: 'Your message',
    LL_Kontaktformular_Subject: 'Existing order or operation number',
    LL_Kontaktformular_Message: 'Message',
    LL_Kontaktformular_Submit: 'Send',
    LL_Kontaktformular_Anhang_Subline: 'Select attachment',
    LL_Kontaktformular_Support: 'Desktop support in consultation with our service team',
    LL_Kontaktformular_Erfolgsmeldung_Headline: 'Thank you for your message.',
    LL_Kontaktformular_Erfolgsmeldung_Text: 'Our service team will contact you shortly.',
    LL_Profil_UstID_Headline_Profil: 'VAT identification number',
    LL_Profil_UstID: 'VAT ID Number',
    LL_Profil_UstID_Syntax_Copy_2:
        'You can retrieve the stored Information via the following link:',
    LL_VAT_Kundeninformation: 'Important user information',
    LL_VAT_WhileValidation_Copy_1:
        'Your VAT ID is empty or the validation of your VAT ID has expired. As long as the validation is not successful, we have to charge you the VAT of your country.',
    LL_VAT_WhileValidation_Copy_2:
        'If your VAT ID cannot be verified, please contact our service team.',
    LL_VAT_NotValid: 'VAT Warning',
    LL_VAT_OutOfService: 'The service is currently unavailable.<br/>Please try again later.',
    LL_VAT_Warning_CheckOut_Copy_5:
        'The VAT ID must be valid and verified in the profile <u><strong>before</strong></u> placing an order. Subsequent invoice changes are not possible.',
    LL_VAT_Warning_CheckOut_Copy_8:
        'Validate your VAT ID in your <a href=https://www.marketingcenter.remax.eu/shop/profile>profile</a>.',
    LL_Checkout_MBW_Hinweis_Headline: 'Minimum order value from the UK',
    LL_Checkout_MBW_Hinweis_Copy_1:
        'Due to the introduction of UK import taxes for shipments from the EU since January 1st 2021, a minimum order value of GBP 135.00 applies in the RE/MAX Marketing Center. Any customs duties and taxes are the responsibility of the buyer. Please contact your responsible authorities before ordering if you have any further questions.',
    LL_Checkout_MBW_Hinweis_Mouseover: 'The minimum order value is GBP 135.00',
    LL_Homepage_Corona_Userinfo_Headline: 'Important!',
    LL_Homepage_Corona_Userinfo_Copy_1:
        'Make sure that at the delivery address you specified, a person is on site to accept the parcel from 8 am to 6 pm.',
    LL_Homepage_Corona_Userinfo_Copy_2:
        '<br/>For deliveries to your home address, make sure that the delivery address shows exactly the name that is on your doorbell. If necessary, replace the company name with your own name, even if it appears twice in the delivery address.',
    LL_Homepage_Corona_Userinfo_Copy_3: '#leer#',
    LL_onOffice_Info_V3_02_Freischaltung_Schritt_2: 'Verbindung mit onOffice',
    LL_onOffice_Info_V3_02_Freischaltung_Schritt_2_01:
        'Folgende onOffice Benutzerdaten werden übernommen.<br><br>',
    LL_onOffice_Info_V3_02_Freischaltung_Schritt_2_02:
        '<strong>Geschafft!</strong> Teste als erstes das onOffice-Exposé, welches für die Verwendung mit onOffice optimiert ist. Mit dem Exposé-Objektbrowser sollten deine Objekte aus onOffice sichtbar sein. Sollte etwas nicht funktionieren, kontaktiere bitte unser Service-Team.',
    LL_onOffice_Info_V3_02_Freischaltung_Userprofil_01:
        '<strong>Datenanbindung an onOffice-Objektdaten/-bilder</strong> ',
    LL_onOffice_Info_V3_02_Freischaltung_Userprofil_02:
        'Kostenlos für alle onOffice-User in Deutschland',
    LL_onOffice_Info_V3_02_Freischaltung_Userprofil_03:
        'Einfache Datenübernahme für die schnelle und einfache Herstellung von hochwertigen Exposé-Broschüren and anderen Druckprodukten im RE/MAX Marketing Center.',
    LL_onOffice_Info_V3_02_Freischaltung_Userprofil_04: '<strong>Verbindungsstatus</strong>',
    LL_onOffice_Info_V3_02_Freischaltung_Userprofil_Status_01: '*Konto ist noch nicht verbunden*',
    LL_onOffice_Info_V3_02_Freischaltung_Userprofil_Status_02: '*Konto ist verbunden*',
    LL_onOffice_Info_V3_02_Freischaltung_Userprofil_entfernen: 'Verbindung entfernen',
    LL_onOffice_Info_V3_02_Freischaltung_Userprofil_05: '<b>Wie erfolgt die Freischaltung?</b>',
    LL_onOffice_Info_V3_02_Freischaltung_Userprofil_06:
        'Gehe zur Schritt-für-Schritt-Verknüpfung in onOffice zum Menüpunkt Marketplace. Klicke auf die Kachel RE/MAX Marketing Center und folge den Anweisungen. <br><br>➜ <a href=https://www.marketingcenter.remax.eu/downloads/RE-EU-DE-Guide-onOffice-RMC-Anbindung.pdf target=_blank>Anleitung zur onOffice-Anbindung</a>',
    LL_onOffice_Info_V3_02_Freischaltung_Userprofil_07:
        'Wichtiger Hinweis für den Import von Bildern:',
    LL_onOffice_Info_V3_02_Freischaltung_Userprofil_08:
        'Für die Verwendung von hochauflösenden Bildern, solltest du die Einstellungen von onOffice anpassen. Setze den Haken bei Bilder in hoher Qualität speichern unter ',
    LL_onOffice_Info_V3_02_Freischaltung_Userprofil_09:
        'Menü > Einstellungen > Grundeinstellungen > Tab: Allgemein > Bildformat<br><br>',
    LL_onOffice_Info_V3_02_Verbindungsstatus_Servicedaten: 'onOffice Servicedaten',
    LL_onOffice_Info_V3_02_Trennen_Headline: 'onOffice-Verbindung trennen',
    LL_onOffice_Info_V3_02_Trennen_Copy_1:
        'Mit Klicken auf Bestätigen wird die Anbindung zu onOffice getrennt und ist nicht mehr aktiv.',
    LL_onOffice_Info_V3_02_Trennen_Copy_2:
        'Nach dem Zurücksetzen der Verbindung kannst du im <i>onOffice Market Place</i> die Verbindung erneut herstellen.',
    LL_IWI_Upload_Success: 'Upload was successful.',
    LL_IWI_Upload_Fail_Error:
        'Upload failed: Please pay attention to the requirements for profile pictures.',
    LL_IWI_Upload_Fail_TooLarge:
        'Upload failed: The image file is too large. Please pay attention to the requirements for profile pictures.',
    LL_Profil_Headline_PortraitPicture: 'Standard Portrait Photo',
    LL_Profil_PortraitPictureService_Infotext_1: 'Send us your portrait photo.',
    LL_Profil_PortraitPictureService_Infotext_2:
        'Please only upload classic portrait photos here! Processing: ca. 2 working days',
    LL_Profil_PortraitPictureService_Instruction_PDF_Link_Text:
        '➜ Portrait image requirements (PDF)',
    LL_Profil_PortraitPictureService_Instruction_PDF_Link_File: 'REMAX_Portrait_Briefing_en-GB.pdf',
    LL_OnBoarding_Popup_General_Headline: 'Portrait Picture Upload',
    LL_OnBoarding_Popup_1_Headline: 'First impression counts',
    LL_OnBoarding_Popup_1_Copy:
        'Have a professional photo taken and submit a high-resolution photo. We will optimise your photo and remove the background.',
    LL_OnBoarding_Popup_2_Headline: 'Submit a photo',
    LL_OnBoarding_Popup_2_Copy:
        'Follow the requirements in the photo guide to achieve the best result!',
    LL_OnBoarding_Popup_3_Copy:
        '<b>Photo ready?</b><br>Simply upload the photo at the end of this tutorial. It will then be processed by us.',
    LL_OnBoarding_Popup_4_Copy:
        '<b>Within 48 hours</b> your picture will be available for your business cards, signboards, flyers etc.',
    LL_OnBoarding_Popup_5a_Headline: 'Photo in portrait format',
    LL_OnBoarding_Popup_5a_Copy:
        'Your photo will automatically be loaded in the layouts with portrait image (print products, social media tools). You can also optionally hide the image.',
    LL_OnBoarding_Popup_5b_Headline: 'Photo of upper body',
    LL_OnBoarding_Popup_5b_Copy:
        'The body photo can be manually adjusted in the provided print products. You can adjust the position size and cropping of the image as you wish.',
    LL_OnBoarding_Popup_6_Headline: 'Upload photo',
    LL_OnBoarding_Popup_6_Copy:
        'Please use a high resolution image and follow our quality guidelines.',
    LL_OnBoarding_Popup_Download_PortraitBriefing: '➜ Download the Portrait Photo Guide',
    LL_OnBoarding_Popup_7_Headline: 'E-mail address and name',
    LL_OnBoarding_Popup_7a_Copy:
        'Please enter the first and last name of the person depicted in the photo.',
    LL_OnBoarding_Popup_7b_Copy:
        'The email address is used to notify you when the image is available in your account.',
    LL_OnBoarding_Popup_Bodyphoto_Headline: 'Body Photo Upload',
    LL_Profile_ProfilFoto_InBearbeitung: 'Order in process',
    LL_Profile_ProfilFoto_Label_1: 'Your photo is ready to use in our templates.',
    LL_Profile_ProfilFoto_Label_2:
        'Please only submit body photos with high resolution and a neutral background!',
    LL_Profile_ProfilFoto_Label_3: 'We are currently working on your uploaded photo.',
    LL_Profile_ProfilFoto_Text_1:
        'Your photo will automatically appear in all layouts supporting a body image.',
    LL_Profile_ProfilFoto_Text_2:
        'Processing: ca. 2 working days -- Your latest photos will appear in your image collection.',
    LL_Profile_ProfilFoto_Text_3:
        'We will send you an email as soon as your photo is ready for use.',
    LL_OnBoarding_Popup_8_Headline: 'Order agreement declaration portrait photo',
    LL_IWI_Warning_MissingQuality:
        'Your image does not meet the quality requirements (min. Resolution 827 x 1063 pixels',
    RMC_IWI_Image_Select_Button: 'Select image file',
    LL_IWI_Requirement_File: 'File format: JPG (max. 20 MB)',
    LL_IWI_Requirement_Resolution: 'Minimum resolution: 827 x 1063 pixels',
    RMC_IWI_AV_Copy_1: 'I hereby confirm ',
    RMC_IWI_AV_Copy_3:
        'that it is myself depicted on the uploaded image and / or I own the full rights to submit the images to the bitsinmotion GmbH (RE/MAX Marketing Center) for further processing (retouching and storage). The goal of the upload is the use of the image in print and digital products within the RE/MAX Marketing Center. ',
    RMC_IWI_AV_Copy_4:
        'The metadata (EXIF) of the uploaded image will be <strong>removed automatically after the upload</strong>. The executives only receive the pure pixel data provided by you. The original image you provide will be deleted after the editing process and will no longer be held or processed. We keep the finished profile picture as a pure pixel image (format: JPG), without personal metadata. You can delete the image at any time in the',
    RMC_IWI_AV_Copy_5: '. (Deletion according to Art. 17 GDPR). ',
    RMC_IWI_AV_Copy_6:
        'If more than one person is depicted, we delete the data directly and reserve the right to refuse the order (uniqueness and identification). ',
    LL_Header_Themen: 'Become the best!',
    LL_Header_Menu: 'Menu',
    LL_ProductDetailPage_CreateProduct: 'Create product',
    LL_Breadcrumps_Start: 'Home',
    LL_Produktinformation_Kaufen: 'Buy',
    LL_General_Yes: 'Yes',
    LL_General_Error: 'Error',
    LL_General_Attention: 'Attention!',
    LL_General_CharsNumbers_Allowed: 'No special characters allowed (only A-Z/a-z and digits).',
    LL_General_all: 'All',
    LL_General_Header_Shop: 'Shop',
    LL_General_Header_Topics: 'Topics',
    LL_General_Header_YourCredit: 'Your credit:',
    LL_General_Header_BuyCredit: 'Buy credit',
    LL_General_Header_Logout: 'Logout',
    LL_General_Header_NoResult1: 'No results found for',
    LL_General_Header_NoResult2: '.',
    LL_General_Header_ChooseLanguage: 'Choose your language',
    LL_General_Header_MySavedProducts: 'Watchlist',
    LL_General_Header_ShoppingCart_Preview: 'Preview:',
    LL_General_Header_ShoppingCart_Amount: 'Amount',
    LL_General_Header_ShoppingCart_Price: 'Price',
    LL_General_Header_ShoppingCart_Tooltip: 'To your shopping cart',
    LL_General_Header_ShoppingCart_ChangeDate: 'Date of change',
    LL_UserEditCustom_Description: 'Product description',
    LL_Navi_Subjects_Seasonal: 'New products',
    LL_Navi_Subjects_Seasonal_Christmas: 'Christmas',
    LL_Footer_Impressum: 'Legal notice',
    LL_Footer_Haftungsausschluss: 'Liability exclusion',
    LL_Footer_AGB: 'GTC',
    LL_Footer_Datenschutz: 'Privacy Statement',
    LL_Footer_Visitenkarten: 'Business cards',
    LL_Footer_Schilder: 'Signs',
    LL_Footer_Faltschilder: 'Folding Signs',
    LL_Footer_Flyer: 'Cards and flyers',
    LL_Footer_Broschure: 'Brochures',
    LL_Footer_Anzeige: 'Ads',
    LL_Footer_Alle_Produkte: 'All products',
    BL_Label_Pflichtfeld: 'Mandatory field',
    LL_General_bits_Telefon_Suffix_lang: 'Phone',
    LL_General_bits_Telefon_Suffix_Chars_14: '+491234567890 (max. 14 characters)',
    LL_Packages: 'Packages',
    LL_Signature: 'Signature',
    LL_General_Versandkosten: 'Packing & shipping costs',
    LL_General_Zwischensumme: 'Subtotal',
    LL_General_Gewicht: 'Weight',
    LL_General_zzgl_X_Mehrwertsteuer: 'Tax',
    LL_General_Lieferadresse: 'Delivery address (check/change)',
    LL_General_ShippingAddress: 'Registered co. name',
    LL_General_AnzahlPakete: 'Quantity of parcels',
    LL_General_Anzahl_Auslieferungen: 'Quantity of deliveries',
    LL_General_Bearbeiten: 'Edit',
    LL_General_Speichern: 'Save',
    LL_General_GespeicherteProdukte: 'Watchlist',
    LL_General_Template: 'Template',
    LL_General_MyMediaManager: 'My image library',
    LL_General_BenutzerProfil: 'Profile settings',
    LL_General_Bestellhistorie: 'Order history',
    LL_General_MeineBestellungen: 'Order history',
    LL_General_Bilderservice: 'To the upload',
    LL_General_Abbrechen: 'Cancel',
    LL_General_Bestaetigen: 'Confirm',
    LL_General_Produkte: 'Products',
    LL_General_Konto: 'Account',
    LL_General_Schliessen: 'Close',
    LL_Land_AL: 'Albania (AL)',
    LL_Land_AT: 'Austria (AT)',
    LL_Land_BE: 'Belgium (BE)',
    LL_Land_BA: 'Bosnia and Herzegovina (BA)',
    LL_Land_BG: 'Bulgaria (BG)',
    LL_Land_HR: 'Croatia (HR)',
    LL_Land_CY: 'Cyprus (CY)',
    LL_Land_CZ: 'Czech Republic (CZ)',
    LL_Land_DK: 'Danmark (DK)',
    LL_Land_EE: 'Estonia (EE)',
    LL_Land_FI: 'Finland (FI)',
    LL_Land_FR: 'France (FR)',
    LL_Land_DE: 'Germany (DE)',
    LL_Land_GR: 'Greece (GR)',
    LL_Land_HU: 'Hungary (HU)',
    LL_Land_IS: 'Iceland (IS)',
    LL_Land_IE: 'Ireland (IE)',
    LL_Land_IL: 'Israel (IL)',
    LL_Land_IT: 'Italy (IT)',
    LL_Land_LV: 'Latvia (LV)',
    LL_Land_LT: 'Lithuania (LT)',
    LL_Land_LU: 'Luxembourg (LU)',
    LL_Land_ME: 'Montenegro (ME)',
    LL_Land_MK: 'Macedonia (MK)',
    LL_Land_MT: 'Malta (MT)',
    LL_Land_NL: 'Netherlands (NL)',
    LL_Land_NO: 'Norway (NO)',
    LL_Land_PL: 'Poland (PL)',
    LL_Land_PT: 'Portugal (PT)',
    LL_Land_RO: 'Romania (RO)',
    LL_Land_RS: 'Serbia (RS)',
    LL_Land_SK: 'Slovakia (SK)',
    LL_Land_SI: 'Slovenia (SI)',
    LL_Land_ES: 'Spain (ES)',
    LL_Land_SE: 'Sweden (SE)',
    LL_Land_CH: 'Switzerland (CH)',
    LL_Land_TR: 'Turkey (TR)',
    LL_Land_GB: 'United Kingdom (UK)',
    'LL_Sprache_sq-AL': 'Albanian (Albania)',
    'LL_Sprache_fr-BE': 'French (Belgium)',
    'LL_Sprache_nl-BE': 'Dutch (Belgium)',
    'LL_Sprache_de-AT': 'German (Austria)',
    'LL_Sprache_bg-BG': 'Bulgarian (Bulgaria)',
    'LL_Sprache_hr-HR': 'Croatian (Croatia)',
    'LL_Sprache_de-DE': 'German (Germany)',
    'LL_Sprache_et-EE': 'Estonian (Estonia)',
    'LL_Sprache_fr-FR': 'French (France)',
    'LL_Sprache_fi-FI': 'Finnish (Finland)',
    'LL_Sprache_el-GR': 'Greek (Greece)',
    'LL_Sprache_el-CY': 'Greek (Cyprus)',
    'LL_Sprache_hu-HU': 'Hungarian (Hungary)',
    'LL_Sprache_is-IS': 'Icelandic (Iceland)',
    'LL_Sprache_it-IT': 'Italian (Italy)',
    'LL_Sprache_lv-LV': 'Latvian (Latvia)',
    'LL_Sprache_lt-LT': 'Lithuanian (Lithuania)',
    'LL_Sprache_fr-LU': 'French (Luxembourg)',
    'LL_Sprache_de-LU': 'German (Luxembourg)',
    'LL_Sprache_sr-ME': 'Montenegrin (Montenegro)',
    'LL_Sprache_mt-MT': 'Maltese (Malta)',
    'LL_Sprache_nl-NL': 'Dutch (Netherlands)',
    'LL_Sprache_nb-NO': 'Norwegian (Norway)',
    'LL_Sprache_pl-PL': 'Polish (Poland)',
    'LL_Sprache_pt-PT': 'Portuguese (Portugal)',
    'LL_Sprache_ro-RO': 'Romanian (Romania)',
    'LL_Sprache_sr-RS': 'Serbian (Serbia)',
    'LL_Sprache_sl-SI': 'Slovenian (Slovenia)',
    'LL_Sprache_es-ES': 'Spanish (Spain)',
    'LL_Sprache_sv-SE': 'Swedish (Sweden)',
    'LL_Sprache_de-CH': 'German (Swiss)',
    'LL_Sprache_fr-CH': 'French (Swiss)',
    'LL_Sprache_it-CH': 'Italian (Swiss)',
    'LL_Sprache_sk-SK': 'Slovakian (Slovakia)',
    'LL_Sprache_cs-CZ': 'Czech (Czech Republic)',
    'LL_Sprache_en-GB': 'English (United Kingdom)',
    LL_Login_MarketingCenter: 'Login to the RE/MAX Marketing Center',
    LL_Login_Benutzername: 'Username',
    LL_Login_Passwort: 'Password',
    LL_Login_iConnect: 'Login via iConnect',
    LL_Login_AT: 'Login for users from Austria',
    LL_Login_AT_Copy:
        'You are a user from the network of RE/MAX Austria? Then please click on the following button to log in. ',
    LL_Login_AT_Button: '➜ Click here to login',
    LL_OnBoarding_Popup_Step_Send: 'Send',
    LL_OnBoarding_Popup_Step_Next: 'Next',
    LL_OnBoarding_Popup_Step_Prev: 'Back',
    LL_Header_Service_Lieferung_Versandkosten: 'Shipping costs',
    LL_Header_Service_Zahlung_Zahlungsmoeglichkeiten: 'Type of payment',
    LL_Shop_Communication_UserBlocked_1:
        'Your account is blocked. Please contact our service team for more details.',
    LL_Shop_Communication_Profile_Incomplete_1: 'Please complete your billing information',
    LL_Shop_Communication_Profile_Incomplete_2:
        'You have not entered any billing information yet. Please complete the information in your profile settings.',
    LL_Shop_Communication_Link_Label_EditProfile: 'Edit profile / complete missing information',
    LL_Shop_Communication_ProfileComplete_1: 'Please complete your profile! Your profile is only',
    LL_Shop_Communication_ProfileComplete_2: 'completed.',
    LL_ServiceInfo_Headline: 'Do you have any questions?',
    LL_ServiceInfo_Copytext_1: 'Simply click here and make use of our',
    LL_ServiceInfo_Copytext_2: 'We will answer your request as soon as possible.',
    LL_ServiceInfo_Copytext_3:
        'If you wish a callback, please note that we only speak English or German.',
    LL_ServiceInfo_Customer_ID: 'CustomerID',
    LL_Profil_Benutzerkonto: 'My user account',
    LL_Profil_Accountdaten: 'Account data',
    LL_Profil_Headline_Personendaten: 'Personal details',
    LL_Profil_FirstName: 'First name',
    LL_Profil_LastName: 'Last name',
    LL_Profil_FirstName_LastName: 'First name / Last name',
    LL_Profil_FirmierungMakler: 'Registered co. Name - Agent',
    LL_Profil_FirmierungMakler_Placeholder: 'Registered co. Name - Agent (optional)',
    LL_Profil_Funktion1: 'Job title (1)',
    LL_Profil_Funktion1_Placeholder: 'Job title (1)',
    LL_Profil_Funktion2: 'Job title (2)',
    LL_Profil_Funktion2_Placeholder: 'Job title (2)',
    LL_Profil_EmailAddress: 'E-mail address',
    LL_Profil_EmailAddress_Placeholder: 'E-mail address',
    LL_Profil_Mobil: 'Mobile',
    LL_Profil_Telefon: 'Telephone',
    LL_Profil_Fax: 'Fax',
    LL_Profil_Template_Optional_Headline_1: 'Optional fields for personalisation',
    LL_Profil_Template_Optional_Copy_1:
        'We recommend filling in these fields completely. The information will be automatically loaded into your customisable print products.',
    BL_Profile_Newsletter_Widget_1_Copy:
        'Don’t miss out on important updates, new products, special offers and competitions in the Marketing Center with our free newsletter! You can cancel your subscription any time at this place.',
    BL_Profile_Newsletter_Widget_2_Headline: 'Contact by WhatsApp',
    BL_Profile_Newsletter_Widget_2_Copy:
        'Now you can also get in touch with us on WhatsApp during our office hours (weekdays 9am-5pm). Click on the button to use WhatsApp on your computer.',
    LL_Profile_Saved_Toast: 'Profile saved',
    LL_Profile_CC: 'Copy to email address',
    LL_Profile_UstIdValidieren: 'Validate VAT ID',
    LL_Profile_UstIdLandFormatText:
        'The VAT-No. of your country is set up in the following scheme:',
    LL_Profile_UstIdEuKommissionLink:
        '<strong>Verify your VAT Reg. No. at the European commisson</strong>.',
    LL_Profile_UstIdInfoboxText:
        'Either the validation of your VAT Reg.No. has expired or no validation has been carried out yet. Please go to VAT Reg.No. and click on Validate/update VAT ID and and finish by clicking on Save.',
    LL_Profile_OnOffice_Headline: 'OnOffice ',
    LL_Profil_Headline_OfficeAdresse: 'Office details',
    LL_Profil_Bueroname: 'RE/MAX office name',
    LL_Profil_Bueroname_Placeholder: 'Office name',
    LL_Profil_OfficeFirmierung: 'Registered co. name',
    LL_Profil_OfficeFirmierung_Placeholder: 'Official company name',
    LL_Profil_Address1: 'Street name and no.',
    LL_Profil_Address2: 'Address supplement',
    LL_Profil_Address2_Placeholder: 'Address supplement',
    LL_Profil_PostalCode: 'Postcode',
    LL_Profil_PostalCode_Placeholder: 'ZIP',
    LL_Profil_City: 'City',
    LL_Profil_Kombi_PostalCode_City: 'ZIP code / City',
    LL_Profil_Country: 'Country',
    LL_Profil_OfficeEmailAddress: 'Office e-mail address',
    LL_Profil_OfficeEmailAddress_Placeholder: 'RE/MAX office e-mail address',
    LL_Profil_Webseite: 'Your RE/MAX URL',
    LL_Profil_Webseite_Placeholder: 'Website',
    LL_Profile_Headline_Rechnungsdaten: 'Invoice recipient',
    LL_Profil_RechEmailAddress_Placeholder: 'E-mail address for invoice delivery',
    LL_Profil_RechFirmierung: 'Registered co. name',
    LL_Profil_Vervollstaendigkeitsanzeige_1:
        'The data stored in the user profile is used for the personalisation of printed products as well as for parcel delivery.',
    BL_ShoppingCart_Artikel_Deleted_OK: 'The product has been successfully deleted.',
    BL_ShoppingCart_Artikel_Copy_OK: 'The product has been successfully duplicated.',
    BL_ShoppingCart_Artikel_Rename_OK: 'The product has been successfully renamed.',
    LL_MultiUser_Proceed: 'Submit',
    LL_UserEditPrinting_Gesamtpreis: 'Total invoice',
    LL_UserEditPrinting_zzglMwst: 'plus VAT.',
    LL_UserEditPrinting_Stk: 'Units',
    LL_ShoppingCart_Artikel: 'Product',
    LL_ShoppingCart_Änderungsdatum: 'Date Modified',
    LL_ShoppingCart_Menge: 'Quantity',
    LL_ShoppingCart_Zurueck: 'Continue Shopping',
    LL_ShoppingCart_Weiter: 'Proceed to Checkout',
    LL_ShoppingCart_Loeschen: 'Delete',
    LL_ShoppingCart_Vormerken: 'Add to watchlist',
    LL_ShoppingCart_Kopieren: 'Duplicate',
    LL_ShoppingCart_Vorschau: 'Preview PDF',
    LL_ShoppingCart_ZumWarenkorb: 'Add to shopping cart',
    LL_ShoppingCart_JustSaved: 'Edit and order',
    LL_ShoppingCart_ConfirmDelete:
        'Sure you want to delete the product permanently?<br><br>Tip: Click Add to watchlist if want to save the product for a later order.',
    LL_ShoppingCart_ConfirmDelete_01: 'Do you really want to delete the product permanently?',
    LL_ShoppingCart_ConfirmDelete_02:
        'Tip: Click Add to watchlist if want to save the product for a later order.',
    LL_ShoppingCart_Delete_Success: 'Product successfully deleted',
    LL_ShoppingCart_Delete_NoSuccess:
        'Product could not be deleted. If the problem persists, please contact our',
    LL_ShoppingCart_ExklMwst: 'excl. tax',
    LL_General_GespeicherteProdukteLeer: 'There are currently no items on your shopping list.',
    LL_Checkout_WarenkorbLeer: 'There are currently no items in your shopping cart.',
    LL_ShoppingCart_Rename_Popup: 'Rename product',
    LL_ShoppingCart_Umbenennen: 'Rename',
    LL_ShoppingCart_Copy: 'Copy',
    LL_ShoppingCart_NewName: 'New product name',
    LL_ShoppingCart_SearchProduct: 'Search product name',
    LL_ShoppingCart_NoSearchResult: 'Your search did not return any results.',
    LL_ShoppingCart_Section: 'Section',
    LL_ShoppingCart_Search: 'Search shopping cart',
    LL_ShoppingCart_Section_OnHold: 'Watchlist',
    LL_ShoppingCart_Section_InEdit: 'Products in progress',
    LL_ShoppingCart_ConfirmDelete_Multiple_01: 'Do you really want to delete these',
    LL_ShoppingCart_ConfirmDelete_Multiple_02: 'products permanently?',
    LL_ShoppingCart_Warenkorb_Subline: 'All products that you would like to order are listed here.',
    LL_ShoppingCart_inEdit_Subline:
        'Here you will find all the saved templates that you still want to edit.',
    LL_ShoppingCart_Watchlist_Subline:
        'You can park products on the watch list so that you can order them at a later time.',
    LL_ShoppingCart_MyOrders_Subline:
        'Here you will find all current and completed orders including invoices and information about the delivery status. Tip: Use products you have already ordered as a template to configure them again.',
    LL_ShoppingCart_Overview: 'Order no. and product overview',
    LL_ShoppingCart_OrderPeriod: 'Limit order period',
    LL_ShoppingCart_Orderinfo: 'Order info',
    LL_ShoppingCart_Warning_100_Headline: 'Warning: Maximum number of products reached',
    LL_ShoppingCart_Warning_100_Copy_1:
        'There are currently 75 or more products in your shopping cart or among your saved products. This can cause performance failures in the shop system.',
    LL_ShoppingCart_Warning_100_Copy_2:
        'Please complete planned orders in time or delete products that are no longer needed from the shopping cart or from the watch list.',
    LL_ShoppingCart_Warning_100_Copy_4:
        'Please contact our support team with the contact form if you have any questions on this issue.',
    LL_Checkout_Header: 'Place order',
    LL_Checkout_Back: 'Back to the shopping cart',
    LL_Checkout_Warenkorb_Headline: 'Shopping Cart',
    LL_Checkout_Lieferadresse: 'Delivery address',
    LL_Checkout_EditAddressInProfile: 'Edit address in your profile',
    LL_RegEx_Email_EmailNotValid: 'Doesn’t look like an email address',
    LL_ShippingAddress_ConfirmDelete: 'Do you really want to delete this address?',
    LL_ShippingAddress_Deleted: 'The address has been deleted.',
    LL_CheckoutShipping_NeueAdresse: 'Add a new shipping address',
    LL_CheckoutShipping_NeueAdresseSpeichern: 'Save new delivery address',
    LL_CheckoutPayment_Rechnungsadresse: 'Invoice address',
    LL_CheckoutPayment_InfoxBox_Vorkasse:
        '<strong>Your order will be processed after receipt of payment.</strong><br>The order confirmation with all payment details will be sent by e-mail.',
    LL_CheckoutPayment_Rechnung: 'Invoice',
    LL_CheckoutPayment_InfoxBox_Rechnung:
        '<strong>Please note:</strong> The invoice will be sent by e-mail (PDF) and is immediately payable after receipt.',
    LL_CheckoutPayment_Button_Paypal_CC_other: 'Online Payment',
    LL_CheckoutPayment_Button_Rechnung: 'Invoice',
    LL_CheckoutPayment_InfoxBox_Paypal:
        'Our online payments are processed via PayPal. A PayPal account is not required. Please confirm the terms & billing address below and select a payment method at the bottom of the page. You will then be redirected to PayPal to complete the payment. Don’t close the window and wait until the order confirmation page appears. Only then your order was successful!',
    LL_CheckoutPayment_CCEmailAddress_Head: 'Copy to email address',
    LL_CheckoutPayment_CCEmailAddress_Copy:
        'Here you can specify another email address to which the order confirmation and invoice will be sent. ',
    LL_CheckoutPayment_CCEmailAddress_Important: 'The specification of this address is optional.',
    LL_CheckoutSubmit_InvoiceAddress_Confirm:
        'I hereby confirm that I’m authorized to order to the specified billing address.',
    LL_CheckoutSubmit_AGB_Text: 'I hereby confirm the GTC.',
    LL_CheckoutSubmit_AGB: '(show GTC)',
    LL_CheckoutSubmit_Kauf_Durchfuehren: 'Make binding purchase',
    LL_CheckoutSubmit_Approved_Text: 'Thank you for your order.',
    LL_CheckoutSubmit_Approved_Headline: 'What happens next?',
    LL_CheckoutSubmit_Approved_Copy_1:
        'We have received your order. You will receive a confirmation to the following e-mail address:',
    LL_CheckoutSubmit_Approved_Copy_1_1: 'Your order',
    LL_CheckoutSubmit_Approved_Copy_1_2:
        'has been received. We will send a confirmation to the following e-mail-adress:',
    LL_CheckoutSubmit_Approved_Copy_2:
        'Please check this immediately and contact us if there is an error.',
    LL_CheckoutSubmit_Approved_Copy_3: 'Your order will be shipped as follows:',
    LL_CheckoutSubmit_Approved_Copy_3a: 'Number of partial deliveries:',
    LL_CheckoutSubmit_Approved_Copy_3b: 'Number of packages:',
    LL_CheckoutSubmit_Approved_Copy_4a: 'At the menu',
    LL_CheckoutSubmit_Approved_Copy_4b:
        'you can view the status of your order, track your delivery and edit or order your previous products/documents again.',
    LL_CheckoutSubmit_Approved_Copy_5:
        'The invoice will be sent to the following email(s) <strong>after</strong> the entire order has been shipped:',
    LL_CheckoutSubmit_Approved_Copy_6a: 'For more information please visit our ',
    LL_CheckoutSubmit_Approved_Copy_6b: 'FAQ page. ',
    LL_CheckoutSubmit_Approved_Link: 'Back to shop',
    LL_CheckoutPayment_Submit_Error_Copy:
        'Unfortunately, your PayPal payment could not be completed. This is due to a negative reply from your card-issuing office or your bank. Neither PayPal nor the Marketing Center have any influence on this. Your order can therefore not be processed and has been cancelled. Please contact your payment service provider or bank to ensure that the payment method you have entered with PayPal is accepted for future transactions.',
    LL_CheckoutPayment_Submit_Error_01: 'Select payment method.',
    LL_CheckoutPayment_Submit_Error_02: 'Confirm GTC + order authorisation checkboxes.',
    LL_CheckoutPayment_Submit_Error_03: 'Check/complete delivery address.',
    LL_OrderList_Bestellungen: 'Order Summary',
    LL_OrderListSummary_BestellDatum: 'Date',
    LL_OrderList_Bestell_Artikel_Nr: 'Order ID',
    LL_OrderList_Details: 'Details',
    LL_OrderListSummary_Bestellung: 'Order ID details',
    LL_OrderListSummary_BestellNr: 'Order ID',
    LL_OrderListSummary_BestellZeit: 'Time',
    LL_OrderListSummary_NeuBestellen: 'Reconfigure',
    LL_OrderList_Invoice_Download: 'Invoice',
    LL_OrderList_Positionen: 'Positions',
    LL_OrderList_Zugestellt: 'Delivered',
    LL_OrderList_in_Bearbeitung: 'processing',
    LL_OrderList_Delivery_Details: 'Details for tracking',
    LL_OrderList_Delivery_Scan: 'Scanned',
    LL_OrderList_Delivery_Road: 'On the road',
    LL_OrderList_Delivery_Vehicle: 'In the delivery vehicle',
    LL_OrderList_Delivery_Approx: 'Delivery approx.',
    LL_OrderList_Delivery_Tracking: 'UPS tracking number',
    LL_OrderList_Delivery_When: 'When',
    LL_OrderList_Delivery_Status: 'Status',
    LL_OrderList_Delivery_Where: 'Where',
    LL_OrderList_Delivery_Docs: 'Documents',
    LL_OrderList_Until: 'to',
    LL_OrderList_From: 'from',
    LL_OrderList_Search: 'Full text search',
    LL_OrderList_in_Zustellung: 'In delivery',
    LL_Flipbook: 'E-books',
    LL_ProdMon_Mail_Infotext_3:
        'I have checked and approved the PDF. I am aware that subsequent claims for damages are excluded by the further use of the production PDF.',
    LL_ProdMon_Mail_PayDetails_1: 'Selected payment method',
    LL_Header_Themen_Objectvermarktung: 'Property Marketing',
    LL_Header_Themen_SelfPromotion: 'Self-promotion',
    LL_Themen_SelfPromotion_AddOn_Button2: 'Download PDF',
    LL_Download: 'Download',
    LL_Label_Button_SignUp: 'Sign in',
    LL_Label_Button_Bildersammlung: 'Your image collection',
    LL_Warning_EndOfLife_Copy1: 'The chosen product is unavailable.',
    LL_Warning_EndOfLife_Copy2: 'The alternative product can be found here:',
    LL_Profile_Newsletter: 'Newsletter',
    LL_Profile_Newsletter_Checkbox:
        'Click here and then confirm the opt-in email (check your spam folder if necessary).',
    LL_Checkout_Newsletter_Subscribe:
        'Yes, I would like to receive the RE/MAX Marketing Center Newsletter.',
    LL_Favorites_Headline: 'My favorites',
    LL_Tutorial_Capice: 'Got it!',
    LL_CheckoutSubmit_Datenschutz: 'Read privacy policy.',
    LL_StopNewsletter_Infobox_Copy1: 'Waiting for confirmation - we have sent you an e-mail.',
    LL_StopNewsletter_Infobox_Copy2: 'Resend e-mail.',
    LL_Footer_Widerrufsbelehrung: 'Information of the right of cancellation',
    LL_General_Menue_Kontakt_Button_1: 'Service ticket',
    LL_General_Menue_Kontakt_Button_2: 'Contact form',
    LL_General_Max_Number_Charts: 'Maximum number of characters:',
    LL_Loginhelper_iConnect_Copy_1: 'Click on the button below to log in via iConnect.',
    LL_Loginhelper_iConnect_Copy_2:
        'If you prefer to log in directly to the Marketing Center, please use our Login Assistant.',
    LL_Loginhelper_iConnect_Headline_Directlogin: 'Direct login',
    LL_Loginhelper_Loginassistant_Headline: 'Login Assistant',
    LL_Loginhelper_Loginassistant_Copy_1:
        '<b>Problems with the login? <br>Forgot your password?</b> <br><br>Then just enter <u>your personal RE/MAX email</u> address below and check the status of your account.<br><br>',
    LL_Loginhelper_Popup_keinTreffer_Copy:
        'Unfortunately, we could not find the e-mail address you entered.',
    LL_Loginhelper_Popup_vertippt_Copy: 'Maybe you have entered incorrect character?',
    LL_Loginhelper_Popup_Regionaldirektion_Copy:
        'Otherwise, please contact your regional directorate and ask to be entered in the correct database.',
    LL_Loginhelper_Popup_mehrereAccounts_Copy_1:
        'We have found several accounts for the entered e-mail address.',
    LL_Loginhelper_Popup_mehrereAccounts_Copy_2:
        'For privacy protection and security reasons, we have to send you an e-mail so that you can select the correct account to reset the login data.',
    LL_Loginhelper_Popup_mehrereAccounts_Copy_3: 'Click the green button to do so.',
    LL_Loginhelper_Popup_Button_Senden: 'Send e-mail',
    LL_Loginhelper_Popup_registriert_Copy_1: 'Result: You are already registered!',
    LL_Loginhelper_Popup_registriert_Copy_2: 'Did you forget your password or username?',
    LL_Loginhelper_Popup_registriert_Copy_3: 'Then click on one of the two links to continue:',
    LL_Loginhelper_Button_PW: 'Reset password',
    LL_Loginhelper_Button_BN: 'Forgot your username?',
    LL_Loginhelper_Popup_nicht_registriert_Copy_1: 'Result: You are not registered yet!',
    LL_Loginhelper_Popup_nicht_registriert_Copy_2:
        'Please click the button below. We will then send you an email for the registration.',
    LL_Loginhelper_Popup_iConnectUser_Copy_1: 'You are an iConnect user - ',
    LL_Loginhelper_Popup_iConnectUser_Copy_2:
        'you can access the Marketing Center using the link on the iConnect homepage.',
    LL_Loginhelper_Popup_iConnectUser_Copy_3:
        '<br>If you want to log in directly to the Marketing Center, then complete your registration now.',
    LL_Loginhelper_Popup_iConnectUser_Copy_4: '<br>Click the green button below to do so.',
    LL_Loginhelper_Popup_iConnectUser_Copy_5:
        ' We will send you an e-mail to assign a username and password.',
    LL_Loginhelper_Popup_iConnectUser_Copy_6:
        '<br>Bookmark our login page <b>marketingcenter.remax.eu</b> and save your credentials to your web browser to access the Marketing Center easily.',
    LL_Loginhelper_Popup_Auswahl_Headline: 'Select your account',
    LL_Loginhelper_Popup_Auswahl_Copy_1:
        'Please select the account with which you have login problems. You can then reset the password or have the user name sent to you again.',
    LL_Loginhelper_Tutorialvideo_Label_DE: 'Tutorial Login Assistant (German)',
    LL_Loginhelper_Tutorialvideo_Label_EN: 'Tutorial Login Assistant (English)',
    LL_Tracking_Userprofil_Headline: 'UPS shipment tracking',
    LL_Tracking_Userprofil_Copy_1:
        'Without this entry, your standard e-mail address will be used for shipping notifications.',
    LL_Tracking_Userprofil_Copy_2:
        'Please ensure that the email address is written correctly and add our sender address (service@marketingcenter.remax.eu) to your contact list to ensure successful receipt of the notifications. Check your spam folder if necessary.',
    LL_Tracking_Userprofil_Copy_4:
        'Please note: An order with multiple products may cause multiple shipping notifications depending on the parcel quantity.',
    LL_Tracking_Warenkorb_Headline: 'Shipping notification',
    LL_Tracking_Warenkorb_Check:
        'UPS tracking number (tracking link) for each delivery will be sent to the following e-mail address',
    LL_Tracking_Warenkorb_Emailadresse: 'Email address for shipping notifications',
    LL_Details_ProduktHinweis_OutOfStock: 'Out of stock',
    LL_Details_ProduktHinweis_Expired: 'Order period expired',
    LL_Details_ProduktHinweis_EndOfLife: 'Not available',
    LL_Preisliste_Anzahl: 'Quantity',
    LL_Preisliste_PreisEinzel: 'approx. unit price',
    LL_Preisliste_PreisGesamt: 'Total price',
    BL_Label_TemplateKey: 'Product ID',
    LL_UserEditCustom_Spezifikationen: 'Specifications',
    LL_UserEditCustom_Preise: 'Price',
    LL_Details_ProduktDetails_Headline_RelatedProducts: 'Related products',
    LL_AgentOnb_Kategorie_Navigation_Copy: 'RE/MAX Compass',
    LL_Discount_ShoppingCart_Button_Aktivieren: 'Redeem discount code',
    LL_Discount_Aktivieren_Headline: 'Redeem your discount code',
    LL_Discount_Aktivieren_Copytext:
        'Enter your code in the field and click on Redeem discount code',
    LL_Discount_Aktivieren_Placeholder: 'Enter code here',
    LL_Discount_Aktivieren_Button: 'Redeem discount code',
    LL_Discount_Aktivieren_Gueltig_Headline: 'Congratulations!',
    LL_Discount_Aktivieren_Gueltig_Text_Discount:
        'You have successfully redeemed your discount code.',
    LL_Discount_Aktivieren_Gueltig_Gutschein: 'Voucher',
    LL_Discount_Aktivieren_Gueltig_UsagesLeft: 'Usage left:',
    LL_Discount_Aktivieren_Gueltig_DiscountOnEntireCart: 'order discount',
    LL_Discount_Aktivieren_Ungueltig_Allgemein_Headline: 'We are sorry!',
    LL_Discount_Aktivieren_Ungueltig_Allgemein_Subline: 'The discount code could not be activated.',
    LL_Discount_Aktivieren_Ungueltig_Spezifisch_NichtVerhanden:
        'Unfortunately the code you entered could not be found. Have you perhaps typed it incorrectly?',
    LL_Discount_Aktivieren_Ungueltig_Spezifisch_NichtRegion:
        'Unfortunately the code you entered is not valid for your region and can therefore not be activated.  ',
    LL_Discount_Aktivieren_Ungueltig_Spezifisch_NichtUser:
        'Unfortunately you cannot redeem the code because it is not valid for you. The code is linked to another user account. ',
    LL_Discount_Aktivieren_Ungueltig_Spezifisch_NichtZeit:
        'Unfortunately the code you entered is no longer valid. The term of the promotion has expired. ',
    LL_Discount_Aktivieren_Ungueltig_Spezifisch_NichtArtikel:
        'The code you entered can only be used for a specific product. Please add that product to your Shopping Cart and try again. The discount code you entered has the following attributes:',
    LL_Discount_Aktivieren_Ungueltig_Spezifisch_NichtBuero:
        'Unfortunately the code you entered is not valid for your office and can therefore not be activated.',
    LL_Discount_Aktivieren_Ungueltig_Allgemein_Button: 'Please try again',
    LL_Discount_Aktivieren_Ungueltig_InCart:
        'You have already redeemed the code in your shopping cart.',
    LL_Discount_Aktivieren_Ungueltig_MindestBW: 'Minimum order value is not reached.',
    LL_Discount_Aktivieren_Ungueltig_NoOrder: 'There is no order.',
    LL_Discount_Aktivieren_Ungueltig_OnlyCredit: 'You can only redeem credit codes.',
    LL_Discount_Cart_OfficeFlexAbo: 'Office Flex Tariff',
    LL_Discount_Cart_OfficeFlexAbo_Discount: '10% discount off this product',
    LL_Discount_Cart_Ambassador: 'Ambassador Program',
    LL_Discount_Cart_Ambassador_Discount: '% discount off the Shopping Cart',
    LL_Discount_Cart_Wertgutschein_Wert: 'EUR voucher for your order',
    LL_Discount_Cart_Wertgutschein_Wert2: 'voucher for your order',
    LL_Discount_Cart_DeinRabattPreis: 'Your price',
    LL_Discount_Cart_Button_CodeEntfernen: 'Remove code',
    LL_Discount_Cart_Credit: 'Article discount',
    LL_Discount_Cart_Credit_Guthaben_Head: 'Credit',
    LL_Discount_Cart_Guthaben_UserInfo_1: 'Your account has a net credit for purchases worth:',
    LL_NonUpload_Erstanmeldung_Alias_Leer: 'The username is empty. Please choose a username.',
    LL_NonUpload_Profil_Passwort_Aendern_Link: 'Change password',
    LL_NonUpload_Profil_Passwort_Aendern_Copy_1: 'You can change your password here:',
    LL_NonUpload_Profil_Passwort_Aendern_PW_aktuell: 'Old password',
    LL_NonUpload_Profil_Passwort_Aendern_PW_neu: 'New password',
    LL_NonUpload_Profil_Passwort_Aendern_PW_neu_2: 'Re-enter new password',
    LL_NonUpload_Profil_Alias_Label: 'Username',
    LL_NonUpload_Profil_Alias_Aendern_Copy_1:
        'You can change your username here. Select a username that you can easily remember.',
    LL_NonUpload_Dein_Alias: 'Your username',
    'LL_NonUpload_E-Mail-gesendet':
        'The e-mail has been sent succesfully. Please also check your spam folder.',
    LL_Login_AGB_1: 'By creating an account, you agree to our ',
    LL_NonUpload_Fail_Passwort_Unterschied:
        'The passwords you entered do not match.<br/>Please try again. ',
    LL_NonUpload_Fail_Passwort_Leer: 'You did not enter a password.',
    LL_NonUpload_Fail_Passwort_PasswortFalsch: 'Your current password is incorrect.',
    LL_NonUpload_PopUpHead_Passwort: 'Change password',
    LL_NonUpload_PopUpHead_Benutzername: 'Change username',
};
