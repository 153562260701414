export const localesProfileTax: string[] = [
    'LL_Profil_WaWi_Valdierung_S1_InfoBox_Ausstehend_DE',
    'LL_Profil_WaWi_Valdierung_S1_H1_DE',
    'LL_Profil_WaWi_Valdierung_S1_C1_DE',
    'LL_Profil_WaWi_Valdierung_S1_Option_C1_DE',
    'LL_Profil_WaWi_Valdierung_S1_Option_A_B1_DE',
    'LL_Profil_WaWi_Valdierung_S1_Option_B_B2_DE',
    'LL_Profil_WaWi_Valdierung_S1_Option_A_C1_DE',
    'LL_Profil_WaWi_Valdierung_S1_Option_A_C2_DE',
    'LL_Profil_WaWi_Valdierung_S1_Option_A_C3_DE',
    'LL_Profil_WaWi_Valdierung_S1_Option_A_B2_DE',
    'LL_Profil_WaWi_Valdierung_S1_Option_A_C4_DE',
    'LL_Profil_WaWi_Valdierung_S1_B3_DE',
    'LL_Profil_WaWi_Valdierung_S1_Option_B_C1_DE',
    'LL_Profil_WaWi_Valdierung_S1_Option_B_C2_DE',
    'LL_Profil_WaWi_Valdierung_S1_Option_B_C3_DE',
    'LL_Profil_WaWi_Valdierung_S1_Option_B_B1_DE',
    'LL_Profil_WaWi_Valdierung_S1_Option_B_C4_DE',
    'LL_Profil_WaWi_Valdierung_S1_B3_DE',
    'LL_Profil_WaWi_Valdierung_S3_H1_DE',
    'LL_Profil_WaWi_Valdierung_S3_C1_DE',
    'LL_Profil_WaWi_Valdierung_S5_H1_DE',
    'LL_Profil_WaWi_Valdierung_S5_C1_DE',
    'LL_Profil_WaWi_Valdierung_S5_C2_DE',
    'LL_Profil_WaWi_Valdierung_S5_B1_DE',
    'LL_Profil_WaWi_Valdierung_S6_Option_A_H1_DE',
    'LL_Profil_WaWi_Valdierung_S6_Option_A_C2_DE',
    'LL_Profil_WaWi_Valdierung_S6_C1_DE',
    'LL_Profil_WaWi_Valdierung_S6_B1_DE',
    'LL_Profil_WaWi_Valdierung_S6_C2_DE',
    'LL_Profil_WaWi_Valdierung_S6_B2_DE',
    'LL_Profil_WaWi_Valdierung_S6_B3_DE',
    'LL_Profil_WaWi_Valdierung_S6_Option_B_H1_DE',
    'LL_Profil_WaWi_Valdierung_S6_Option_B_C1_DE',
    'LL_Profil_WaWi_Valdierung_S6_Option_B_C2_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S1_H1_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S1_H2_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S1_C1_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S1_C2_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S1_C3_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S1_B1_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S1_B2_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S3_H1_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S3_H2_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S3_C1_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S3_C2_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S3_C3_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S3_B1_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S5_H1_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S5_H2_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S5_C1_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S5_C2_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S5_B1_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S6_H1_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S6_H2_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S6_C1_DE',
    'LL_PopUp_WaWi_ValdierungInfo_S5_B1_DE',
];
